import React from 'react';
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { DemoHeader } from "../commonComponents"

const styles = {
    fontContact: {
        color: "#fff",
        fontFamily: "DM Sans",
    },
    alignContent: {
        display: "flex",
        alignItems: "center",
    },
}

const myHTML = `
    <script src="https://frontend-api-server-v2.api.irisagent.com/v1/chatbot/bundle"></script>
  <script type='text/javascript'>
    initIrisAgentChatbot( "7a83da25543508459ae0b96cc69de6c7cffacc207e0700e82ecad80811a6" );
  </script>

<div style="display: flex; justify-content: center; height: 90vh;">
  <iframe src="https://app.irisagent.com/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=396a275e79b2245332a102b0199e8a056f7bd1cf" width="50%" style="border: 0;"></iframe>
</div>
<section id="contact" className="contact">
                    <div className="info-container" style={styles.fontContact}>

                    </div>

                </section>

`;

export default function StickyDemo() {
    return (
        <div>
            <DemoHeader
                customer="NVENT"
            />
            <Helmet>
                <meta name={`robots`} content={`noindex, nofollow`} />
                <link
                    rel="canonical"
                    href="https://irisagent.com/nvent-demo/"
                />
            </Helmet>
            <section className="inner-page" style={{paddingTop: "30px", paddingLeft: "20px"}}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            </section>

        </div>
    )
}
